import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import { AppAuthContextProvider } from './utilities/state';
import { AppContextProvider } from './hooks/useApp';

ReactDOM.render(
  <AppContextProvider>
    <AppAuthContextProvider>
      <HelmetProvider>
        <SidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </HelmetProvider>
    </AppAuthContextProvider>
  </AppContextProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();


