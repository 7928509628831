import en from "src/translations/en";
import ms from "src/translations/ms";


const languageRes = {
    en: {
        common: {
            ...en
        }  // 'common' is our custom namespace
    },
    ms: {
        common: {
            ...ms
        }
    },
}

export default languageRes;