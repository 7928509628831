
import { Backdrop, CircularProgress } from '@mui/material';
// import { useParams } from 'react-router';
// import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';


interface LoadingProps {
    isOpen: boolean;
    message?: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            // zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);


const Loading: React.FC<LoadingProps> = ({ isOpen, message }) => {
    const classes = useStyles();
    // const [open, setOpen] = useState(isOpen);
    return (
        <Backdrop className={classes.backdrop} open={isOpen} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2000 }} >
            <CircularProgress color="inherit" />
            {
                message ? <h3>{message}</h3> : null
            }
        </Backdrop>
    )
}


export default Loading;
