import { ReactNode } from 'react';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
// import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
// import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import { RouterLinks } from 'src/utils/routes';
import { IUserType } from 'src/data/constants';
// import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
// import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
// import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
// import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
// import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
// import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
// import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
// import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  permissions: IUserType[];
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'side-menu-page.dashboard',
        link: '/dashboard',
        icon: DesignServicesTwoToneIcon
      }
    ],
    permissions: [IUserType.Admin, IUserType.SuperAdmin]
  },
  // {
  //   heading: 'Dashboards',
  //   items: [
  //     {
  //       name: 'Crypto',
  //       link: '/dashboards/crypto',
  //       icon: BrightnessLowTwoToneIcon
  //     },
  //     {
  //       name: 'Messenger',
  //       icon: MmsTwoToneIcon,
  //       link: '/dashboards/messenger'
  //     },
  //   ],
  //   permissions: [IUserType.Admin, IUserType.SuperAdmin]
  // },
  {
    heading: 'side-menu-page.admin-users',
    items: [
      {
        name: 'side-menu-page.add',
        link: '/admins/add',
        icon: BrightnessLowTwoToneIcon
      },
      {
        name: 'side-menu-page.list',
        icon: MmsTwoToneIcon,
        link: '/admins/list'
      },
    ],
    permissions: [IUserType.Admin, IUserType.SuperAdmin]
  },
  {
    heading: 'side-menu-page.devices',
    items: [
      {
        name: 'side-menu-page.add',
        link: '/devices/add',
        icon: BrightnessLowTwoToneIcon
      },
      {
        name: 'side-menu-page.list',
        icon: MmsTwoToneIcon,
        link: '/devices/list'
      },
    ],
    permissions: [IUserType.Admin]
  },
  {
    heading: 'side-menu-page.vendors',
    items: [
      {
        name: 'side-menu-page.add',
        link: `${RouterLinks.vendor.INDEX}/${RouterLinks.vendor.ADD}`,
        icon: BrightnessLowTwoToneIcon
      },
      {
        name: 'side-menu-page.list',
        icon: MmsTwoToneIcon,
        link: `${RouterLinks.vendor.INDEX}/${RouterLinks.vendor.LIST}`,
      },
    ],
    permissions: [IUserType.SuperAdmin]
  },
  {
    heading: 'side-menu-page.user-roles',
    items: [
      {
        name: 'side-menu-page.add',
        link: `${RouterLinks.roles.INDEX}/${RouterLinks.roles.ADD}`,
        icon: BrightnessLowTwoToneIcon
      },
      {
        name: 'side-menu-page.list',
        icon: MmsTwoToneIcon,
        link: `${RouterLinks.roles.INDEX}/${RouterLinks.roles.LIST}`,
      },
    ],
    permissions: [IUserType.SuperAdmin, IUserType.Admin]
  },
  {
    heading: 'side-menu-page.users',
    items: [
      {
        name: 'side-menu-page.add',
        link: `${RouterLinks.users.INDEX}/${RouterLinks.users.ADD}`,
        icon: BrightnessLowTwoToneIcon
      },
      {
        name: 'side-menu-page.list',
        icon: MmsTwoToneIcon,
        link: `${RouterLinks.users.INDEX}/${RouterLinks.users.LIST}`,
      },
    ],
    permissions: [IUserType.SuperAdmin, IUserType.Admin]
  },
  // {
  //   heading: 'Management',
  //   items: [
  //     {
  //       name: 'Transactions',
  //       icon: TableChartTwoToneIcon,
  //       link: '/management/transactions'
  //     },
  //     {
  //       name: 'User Profile',
  //       icon: AccountCircleTwoToneIcon,
  //       link: '/management/profile',
  //       items: [
  //         {
  //           name: 'Profile Details',
  //           link: '/management/profile/details'
  //         },
  //         {
  //           name: 'User Settings',
  //           link: '/management/profile/settings'
  //         }
  //       ]
  //     }
  //   ],
  //   permissions: [IUserType.SuperAdmin, IUserType.Admin]
  // },
  {
    heading: 'side-menu-page.user-profile',
    items: [
      {
        name: 'side-menu-page.profile-detail',
        link: '/profile/details'
      },
      {
        name: 'side-menu-page.user-settings',
        link: '/profile/settings'
      }
    ],
    permissions: [IUserType.SuperAdmin, IUserType.Admin]
  },
  {
    heading: 'Components',
    permissions: [IUserType.Admin, IUserType.SuperAdmin],
    items: [
      // {
      //   name: 'Buttons',
      //   icon: BallotTwoToneIcon,
      //   link: '/components/buttons'
      // },
      {
        name: 'Modals',
        icon: BeachAccessTwoToneIcon,
        link: '/components/modals'
      },
      // {
      //   name: 'Accordions',
      //   icon: EmojiEventsTwoToneIcon,
      //   link: '/components/accordions'
      // },
      // {
      //   name: 'Tabs',
      //   icon: FilterVintageTwoToneIcon,
      //   link: '/components/tabs'
      // },
      // {
      //   name: 'Badges',
      //   icon: HowToVoteTwoToneIcon,
      //   link: '/components/badges'
      // },
      // {
      //   name: 'Tooltips',
      //   icon: LocalPharmacyTwoToneIcon,
      //   link: '/components/tooltips'
      // },
      // {
      //   name: 'Avatars',
      //   icon: RedeemTwoToneIcon,
      //   link: '/components/avatars'
      // },
      // {
      //   name: 'Cards',
      //   icon: SettingsTwoToneIcon,
      //   link: '/components/cards'
      // },
      // {
      //   name: 'Forms',
      //   icon: TrafficTwoToneIcon,
      //   link: '/components/forms'
      // },
    ]
  },
  // {
  //   heading: 'Extra Pages',
  //   items: [
  //     {
  //       name: 'Status',
  //       icon: VerifiedUserTwoToneIcon,
  //       link: '/status',
  //       items: [
  //         {
  //           name: 'Error 404',
  //           link: '/status/404'
  //         },
  //         {
  //           name: 'Error 500',
  //           link: '/status/500'
  //         },
  //         {
  //           name: 'Maintenance',
  //           link: '/status/maintenance'
  //         },
  //         {
  //           name: 'Coming Soon',
  //           link: '/status/coming-soon'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default menuItems;
