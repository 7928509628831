import React, { createContext, useContext, useEffect, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import logger from "./logger";
import { Get, Save } from './storage';
import { AuthState } from "./iAuth";
import { StorageKeys } from "../data/constants";
import { UserActions } from "./actions";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import languageRes from "./languages";


i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: { ...languageRes },
});

let AppAuthContext = createContext(initialState as any);

const loggerReducer = logger(reducer);

function AppAuthContextProvider(props: any) {

    const temp = Get<AuthState>(StorageKeys.PERSISTED_STATE);
    const persistedState = temp ? temp : {};

    const fullInitialState: AuthState = {
        ...initialState,
        ...persistedState
    };

    let [state, dispatch] = useReducer(loggerReducer, fullInitialState) as unknown as [state: AuthState, dispatch: React.Dispatch<UserActions>];
    let value = { state, dispatch };

    useEffect(() => {
        // window.localStorage["persistedState"] = JSON.stringify(fullInitialState);
        Save(StorageKeys.PERSISTED_STATE, {
            ...fullInitialState,
            ...state
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (state && state.user && state.user.appSettings && state.user.appSettings.language) {
            i18next.init({
                interpolation: { escapeValue: false },  // React already does escaping
                lng: state.user.appSettings.language,   // language to use
                resources: { ...languageRes },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <I18nextProvider i18n={i18next}>
            <AppAuthContext.Provider value={value}>{props.children}</AppAuthContext.Provider>
        </I18nextProvider>
    );
}

let AppContextConsumer = AppAuthContext.Consumer;

// eslint-disable-next-line react-hooks/rules-of-hooks
const useAuthContext = () => useContext(AppAuthContext) as unknown as { state: AuthState, dispatch: React.Dispatch<UserActions> };

export { AppAuthContext, AppAuthContextProvider, AppContextConsumer, useAuthContext };
