
import login from "src/translations/ms/login.json";
import settings_page from "src/translations/ms/edit-profile.json";
import dashboard_page from "src/translations/ms/dashboard-page.json";
import sidebar_page from "src/translations/ms/side-menu-list.json";

const ms = {
    ...login,
    ...settings_page,
    ...dashboard_page,
    ...sidebar_page
}  // 'common' is our custom namespace


export default ms;