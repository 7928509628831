
import login from "src/translations/en/login.json";
import settings_page from "src/translations/en/edit-profile.json";
import dashboard_page from "src/translations/en/dashboard-page.json";
import sidebar_page from "src/translations/en/side-menu-list.json";

const en = {
    ...login,
    ...settings_page,
    ...dashboard_page,
    ...sidebar_page
}  // 'common' is our custom namespace


export default en;