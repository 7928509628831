import { ApiBaseResponse } from "./base";


export interface IAppSettings {

    userId: string;
    language: string;
    themeType: Theme;
    pageSize: number;
    timeZone: string;
}

export enum Theme {
    Auto = 0,
    Light = 1,
    Dark = 2
}


export interface AppSettingsResponse extends ApiBaseResponse<IAppSettings> {
}

