
export const RouterLinks = {
    GLOBAL: '/',
    DEFAULT: '/',
    LOGIN: '/login',
    // DASHBOARD: '/dashboards',
    DASHBOARD: '/dashboard',
    FORGOT_PASSWORD: '/forgot-password',
    NOTFOUND: '/not-found',
    // dashboard: {
    //     PATH: 'dashboards',
    //     INDEX: '/dashboards',
    //     DEFAULT: '/dashboards/crypto',
    //     CRYPTO: 'crypto',
    //     MESSENGER: 'messenger'
    // },
    admin: {
        PATH: 'admins',
        INDEX: '/admins',
        DEFAULT: '/admins/add',
        ADD: 'add',
        EDIT: 'edit/:adminId',
        LIST: 'list'
    },
    device: {
        PATH: 'devices',
        INDEX: '/devices',
        DEFAULT: '/devices/add',
        ADD: 'add',
        EDIT: 'edit/:deviceId',
        LIST: 'list',
        SENSOR: 'sensor/:deviceId'
    },
    vendor: {
        PATH: 'vendors',
        INDEX: '/vendors',
        DEFAULT: '/vendors/add',
        ADD: 'add',
        EDIT: 'edit/:vendorId',
        LIST: 'list'
    },
    roles: {
        PATH: 'roles',
        INDEX: '/roles',
        DEFAULT: '/roles/add',
        ADD: 'add',
        EDIT: 'edit/:roleId',
        LIST: 'list'
    },
    users: {
        PATH: 'users',
        INDEX: '/users',
        DEFAULT: '/users/add',
        ADD: 'add',
        EDIT: 'edit/:userId',
        LIST: 'list'
    },
    facilities: {
        INDEX: '/facilities',
        ADD: '/facilities/add',
        LIST: '/facilities/list',
        EDIT: '/facilities/edit/:facilityId',
    },

    alarms: {
        PATH: 'alarms',
        INDEX: '/alarms',
        DEFAULT: '/alarms/add',
        ADD: '/alarms/add/:sensorId',
        EDIT: '/alarms/edit/:sensorId'
    }
}
